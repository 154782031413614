import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
// tslint:disable: max-line-length

import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ReactiveFormsModule } from '@angular/forms';
import { DragDropModule } from '@angular/cdk/drag-drop';

import { NgxMaskModule } from 'ngx-mask';

// primeNG's p-dropdown dropped the ball so use ng-select for all dropdowns
import { NgSelectModule } from '@ng-select/ng-select';

import { FullCalendarModule } from '@fullcalendar/angular';

import { DropdownModule } from 'primeng/dropdown';
import { TabViewModule } from 'primeng/tabview';
import { MenubarModule } from 'primeng/menubar';
import { ButtonModule } from 'primeng/button';
import { PanelModule } from 'primeng/panel';
import { InputTextModule } from 'primeng/inputtext';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { ProgressBarModule } from 'primeng/progressbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { InputSwitchModule } from 'primeng/inputswitch';
import { MultiSelectModule } from 'primeng/multiselect';
import { DialogModule } from 'primeng/dialog';
import { TooltipModule } from 'primeng/tooltip';
import { ToastModule } from 'primeng/toast';
import { TableModule } from 'primeng/table';
import { CheckboxModule } from 'primeng/checkbox';
import { KeyFilterModule } from 'primeng/keyfilter';
import { MessageService, ConfirmationService } from 'primeng/api';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessageModule } from 'primeng/message';
import { SelectButtonModule } from 'primeng/selectbutton';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { FieldsetModule } from 'primeng/fieldset';
import { AccordionModule } from 'primeng/accordion';
import { SidebarModule } from 'primeng/sidebar';
import { PanelMenuModule } from 'primeng/panelmenu';
import { StepsModule } from 'primeng/steps';
import { SliderModule } from 'primeng/slider';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ColorPickerModule } from 'ngx-color-picker';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { LocationColorService } from './services/location-color.service';
import {
  EquipmentGenSvc, CrewGenSvc, EmployeeGenSvc, WellnessWorkOrderGenSvc, PriorityLevelGenSvc, ScheduledBucketGenSvc,
  ScheduleCommentGenSvc, RoleGenSvc, SkillGenSvc, AddressGenSvc, EquipmentTypeGenSvc,
  WellnessTaskGenSvc, WorkTaskGenSvc, StateGenSvc, ChemicalGenSvc, ChemicalApplicationMethodGenSvc, WellnessTaskTemplateGenSvc,
  WorkTaskTemplateGenSvc, CustomerGenSvc, TreeTypeGenSvc, PaymentDueOptionGenSvc, ChemicalApplicationLocationGenSvc, StripeGenSvc,
  ChemicalComboGenSvc, API_BASE_URL, BigDayBucketGenSvc, BigDayBucketSubrangeGenSvc, BigDayEmployeeGenSvc, QuoteAndAuthenticationGenSvc,
  WorkOrderWorkTaskGenSvc, WorkOrderPriceAdjustmentGenSvc, WorkOrderWellnessTaskGenSvc, CustomerTypeGenSvc,
  BigDayEquipmentGenSvc, AuthenticationGenSvc, PesticideApplicationRecordGenSvc, QuoteGenSvc, WorkWorkOrderGenSvc,
  QuoteRequestGenSvc, NotificationsGenSvc, PriorityTypeShortcutGenSvc, SurveyQuestionGenSvc, SurveyResponseGenSvc, ZipCodeGenSvc,
  BroughtByGenSvc, RegionalManagerTimeSlotGenSvc, RegionalManagerScheduleEventGenSvc, BlobGenSvc, WellnessTaskHourEntryGenSvc, CustomerNoteGenSvc,
  CustomerCommentStageGenSvc, WorkTaskHourEntryGenSvc, NeighborPermissionGenSvc, LogGenSvc, WorkOrderGenSvc, SystemSettingGenSvc, ReportGenSvc, 
  MessageToCustomerTemplateGenSvc, ExportGenSvc
} from './services_autogenerated/generated_services';
import { DragAndDropService } from './services/drag-and-drop.service';
import { SpecificationService } from './services/specification.service';
import { CustomerService } from './services/customer.service';

import { PeopleFilterPipe } from './pipes/people-filter.pipe';
import { EquipmentFilterPipe } from './pipes/equipment-filter.pipe';
import { WorkOrderFilterPipe } from './pipes/work-order-filter.pipe';
import { CrewFilterPipe } from './pipes/crew-filter.pipe';
import { QuoteRequestFilterPipe } from './pipes/quote-request-filter.pipe';

import { DateGreaterThanValidatorDirective } from './directives/min-date-validator.directive';
import { DateLessThanValidatorDirective } from './directives/max-date-validator.directive';
import { TextMaskModule } from 'angular2-text-mask';

import { NumericDirective } from './directives/numeric.directive';
import { TrimDirective } from './directives/trim.directive';

import { WellnessDayViewComponent } from './components/wellness-day-view/wellness-day-view.component';
import { WellnessWeekViewComponent } from './components/wellness-week-view/wellness-week-view.component';
import { SchedulingTabsComponent } from './components/scheduling-tabs/scheduling-tabs.component';
import { WorkDayViewComponent } from './components/work-day-view/work-day-view.component';
import { WorkWeekViewComponent } from './components/work-week-view/work-week-view.component';
import { CrewsAndEquipmentPanelComponent } from './components/crews-and-equipment-panel/crews-and-equipment-panel.component';
import { WorkOrdersPanelComponent } from './components/work-orders-panel/work-orders-panel.component';
import { EquipmentCardComponent } from './components/equipment-card/equipment-card.component';
import { EmployeeCardComponent } from './components/employee-card/employee-card.component';
import { CrewCardComponent } from './components/crew-card/crew-card.component';
import { TaskCardComponent } from './components/task-card/task-card.component';
import { TwoDayCalendarViewComponent } from './components/two-day-calendar-view/two-day-calendar-view.component';
import { WeekCalendarViewComponent } from './components/week-calendar-view/week-calendar-view.component';
import { SkillListComponent } from './components/skill-list/skill-list.component';
import { EmployeeListComponent } from './components/employee-list/employee-list.component';
import { EmployeeMaintenanceComponent } from './components/employee-maintenance/employee-maintenance.component';
import { SkillMaintenanceComponent } from './components/skill-maintenance/skill-maintenance.component';
import { EquipmentMaintenanceComponent } from './components/equipment-maintenance/equipment-maintenance.component';
import { EquipmentListComponent } from './components/equipment-list/equipment-list.component';
import { CrewCreationComponent } from './components/crew-creation/crew-creation.component';
import { PriorityMaintenanceComponent } from './components/priority-maintenance/priority-maintenance.component';
import { ScheduledItemBucketCardComponent } from './components/scheduled-item-bucket-card/scheduled-item-bucket-card.component';
import { ChemicalMaintenanceComponent } from './components/chemical-maintenance/chemical-maintenance.component';
import { ChemicalLocationMaintenanceComponent } from './components/chemical-location-maintenance/chemical-location-maintenance.component';
import { ChemicalMethodMaintenanceComponent } from './components/chemical-method-maintenance/chemical-method-maintenance.component';
import { ChemicalComboMaintenanceComponent } from './components/chemical-combo-maintenance/chemical-combo-maintenance.component';
import { CrewCreationCardComponent } from './components/crew-creation-card/crew-creation-card.component';
import { EquipmentTypeMaintenanceComponent } from './components/equipment-type-maintenance/equipment-type-maintenance.component';
import { WellnessTaskTemplateListComponent } from './components/wellness-task-template-list/wellness-task-template-list.component';
import { WellnessTaskTemplateMaintenanceComponent } from './components/wellness-task-template-maintenance/wellness-task-template-maintenance.component';
import { WorkTaskTemplateMaintenanceComponent } from './components/work-task-template-maintenance/work-task-template-maintenance.component';
import { WorkTaskTemplateListComponent } from './components/work-task-template-list/work-task-template-list.component';
import { CustomerListComponent } from './components/customer-list/customer-list.component';
import { CustomerMaintenanceComponent } from './components/customer-maintenance/customer-maintenance.component';
import { WorkTaskMaintenanceComponent } from './components/work-task-maintenance/work-task-maintenance.component';
import { WellnessTaskMaintenanceComponent } from './components/wellness-task-maintenance/wellness-task-maintenance.component';
import { WorkOrderListComponent } from './components/work-order-list/work-order-list.component';
import { SchedulingMultidayComponent } from './components/scheduling-multiday/scheduling-multiday.component';
import { MultidayJobsPanelComponent } from './components/multiday-jobs-panel/multiday-jobs-panel.component';
import { MultidayJobCardComponent } from './components/multiday-job-card/multiday-job-card.component';
import { MultidaySubrangeCardComponent } from './components/multiday-subrange-card/multiday-subrange-card.component';
import { UnavailableEquipmentAndEmployeesComponent } from './components/unavailable-equipment-and-employees/unavailable-equipment-and-employees.component';
import { MultidayDragAndDropService } from './services/multiday-drag-and-drop.service';
import { MultidayEquipmentAndEmployeesComponent } from './components/multiday-equipment-and-employees/multiday-equipment-and-employees.component';
import { LoginComponent } from './components/login/login.component';
import { TokenInterceptor } from './token.interceptor';
import { AuthGuard } from './auth.guard';
import { AuthHelperService } from './services/auth-helper.service';
import { BehaviorSubjectService } from './services/behavior-subject.service';
import { ScriptLoaderService } from './services/script-loader.service';
import { MyScheduleComponent } from './components/my-schedule/my-schedule.component';
import { WelcomePageComponent } from './components/welcome-page/welcome-page.component';
import { CustomerCommentsComponent } from './components/customer-comments/customer-comments.component';
import { PesticideRecordMaintenanceComponent } from './components/pesticide-record-maintenance/pesticide-record-maintenance.component';
import { PesticideRecordListComponent } from './components/pesticide-record-list/pesticide-record-list.component';
import { WorkOrderMaintenanceCommonFieldsTopComponent } from './components/work-order-maintenance-common-fields-top/work-order-maintenance-common-fields-top.component';
import { WellnessWorkOrderMaintenanceComponent } from './components/wellness-work-order-maintenance/wellness-work-order-maintenance.component';
import { WorkOrderMaintenanceCommonFieldsBottomComponent } from './components/work-order-maintenance-common-fields-bottom/work-order-maintenance-common-fields-bottom.component';
import { QuoteWorkOrderMaintenanceComponent } from './components/quote-work-order-maintenance/quote-work-order-maintenance.component';
import { WorkWorkOrderMaintenanceComponent } from './components/work-work-order-maintenance/work-work-order-maintenance.component';
import { WellnessWorkOrderCardComponent } from './components/wellness-work-order-card/wellness-work-order-card.component';
import { BaseWorkOrderCardComponent } from './components/base-work-order-card/base-work-order-card.component';
import { WorkWorkOrderCardComponent } from './components/work-work-order-card/work-work-order-card.component';
import { DateGreaterThanAllSubrangeValidatorDirective } from './directives/min-date-subranges-validator.directive';
import { DateLessThanAllSubrangeValidatorDirective } from './directives/max-date-subranges-validator.directive';
import { ScheduledBucketWellnessWorkOrderCardComponent } from './components/scheduled-bucket-wellness-work-order-card/scheduled-bucket-wellness-work-order-card.component';
import { BaseWellnessWorkOrderCardComponent } from './components/base-wellness-work-order-card/base-wellness-work-order-card.component';
import { CustomerFilterPipe } from './pipes/customer-filter.pipe';
import { InputMaskModule } from 'primeng/inputmask';
import { MyScheduleCrewCardComponent } from './components/my-schedule-crew-card/my-schedule-crew-card.component';
import { RequestedQuoteListComponent } from './components/requested-quote-list/requested-quote-list.component';
import { RequestedQuoteMaintenanceComponent } from './components/requested-quote-maintenance/requested-quote-maintenance.component';
import { ScheduledBucketWorkWorkOrderCardComponent } from './components/scheduled-bucket-work-work-order-card/scheduled-bucket-work-work-order-card.component';
import { MultidayJobsWorkPanelComponent } from './components/multiday-jobs-work-panel/multiday-jobs-work-panel.component';
import { PriorityService } from './services/priority.service';
import { SharedColorService } from './services/shared-color.service';
import { CalculatedPriorityAgingColorService } from './services/calculated-priority-aging-color.service';
import { PriorityAndDateSelectorComponent } from './components/priority-and-date-selector/priority-and-date-selector.component';
import { WorkOrderFilterBarComponent } from './components/work-order-filter-bar/work-order-filter-bar.component';
import { SurveyMaintenanceComponent } from './components/survey-maintenance/survey-maintenance.component';
import { SurveyResponseComponent } from './components/survey-response/survey-response.component';
import { SurveyListComponent } from './components/survey-list/survey-list.component';
import { SurveyHeaderFilterPipe } from './pipes/survey-header-filter.pipe';
import { WorkOrderMaintenanceWellnessTaskCardComponent } from './components/work-order-maintenance-wellness-task-card/work-order-maintenance-wellness-task-card.component';
import { WorkOrderMaintenanceWorkTaskCardComponent } from './components/work-order-maintenance-work-task-card/work-order-maintenance-work-task-card.component';
import { ZipCodeMaintenanceComponent } from './components/regional-manager-scheduling/zip-code-maintenance/zip-code-maintenance.component';
import { ZipCodeAssignmentComponent } from './components/regional-manager-scheduling/zip-code-assignment/zip-code-assignment.component';
import { ZipCodeAssignmentItemComponent } from './components/regional-manager-scheduling/zip-code-assignment-item/zip-code-assignment-item.component';
import { DayZipCodeAssignmentComponent } from './components/regional-manager-scheduling/day-zip-code-assignment/day-zip-code-assignment.component';
import { DayZipCodeItemComponent } from './components/regional-manager-scheduling/day-zip-code-item/day-zip-code-item.component';
import { SchedulePageComponent } from './components/regional-manager-scheduling/schedule-page/schedule-page.component';
import { QuoteRequestItemComponent } from './components/regional-manager-scheduling/quote-request-item/quote-request-item.component';

import { CustomerLandingPageComponent } from './components/customer-landing-page/customer-landing-page.component';
import { CustomerCurrentTabComponent } from './components/customer-current-tab/customer-current-tab.component';
import { CustomerHistoryTabComponent } from './components/customer-history-tab/customer-history-tab.component';
import { StripeBillingComponent } from './components/stripe-billing/stripe-billing.component';

import { NgxStripeModule } from 'ngx-stripe';
import { AppConfigService } from './services/app-config.service';
import { CustomerBillingProfileComponent } from './components/customer-billing-profile/customer-billing-profile.component';

import { CustomerContactComponent } from './components/customer-contact/customer-contact.component';
import { CustomerWorkOrderListComponent } from './components/customer-pages/customer-work-order-list/customer-work-order-list.component';
import { NewEventModalComponent } from './components/regional-manager-scheduling/new-event-modal/new-event-modal.component';
import { DayZipCodeViewOnlyComponent } from './components/regional-manager-scheduling/day-zip-code-view-only/day-zip-code-view-only.component';
import { BlobManagerComponent } from './components/blob-manager/blob-manager.component';
import { EmployeeSkillUploadComponent } from './components/employee-skill-upload/employee-skill-upload.component';
import { CustomerMainTabComponent } from './components/customer-pages/customer-main-tab/customer-main-tab.component';
import { CustomerWorkOrderRowComponent } from './components/customer-pages/customer-work-order-row/customer-work-order-row.component';
import { CustomerQuoteRowComponent } from './components/customer-pages//customer-quote-row/customer-quote-row.component';
import { CustomerQuotePageComponent } from './components/customer-pages/customer-quote-page/customer-quote-page.component';
import { BillingProfileEntryModalComponent } from './components/customer-billing-profile/billing-profile-entry-modal/billing-profile-entry-modal.component';
import { QuoteBillingComponent } from './components/quote-billing/quote-billing.component';
import { BillingProfileEntryComponent } from './components/customer-billing-profile/billing-profile-entry/billing-profile-entry.component';
import { QuotePaymentComponent } from './components/quote-billing/quote-payment/quote-payment.component';
import { PasswordChangeComponent } from './components/password/password-change/password-change.component';
import { PasswordResetComponent } from './components/password/password-reset/password-reset.component';
import { ContactInfoComponent } from './components/customer-maintenance/contact-info/contact-info.component';
import { QuoteBillingModalComponent } from './components/quote-billing/quote-billing-modal/quote-billing-modal.component';
import { CustomerFirmtimeSchedulingComponent } from './components/customer-firmtime-scheduling/customer-firmtime-scheduling.component';
import { InvoicePartialWorkComponent } from './components/invoice-partial-work/invoice-partial-work.component';
import { InvoicePartialWorkRowComponent } from './components/invoice-partial-work-row/invoice-partial-work-row.component';
import { RequestChangesDialogComponent } from './components/customer-pages/request-changes-dialog/request-changes-dialog.component';
import { TaskHoursEntryComponent } from './components/task-hours-entry/task-hours-entry.component';
import { CustomerCommentViewOnlyComponent } from './components/customer-comment-view-only/customer-comment-view-only.component';
import { SignatureAreaComponent } from './components/signature-area/signature-area.component';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { SignaturePadModule } from 'angular2-signaturepad';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ReportingComponent } from './components/reporting/reporting.component';
import { CustomerFirmtimeQuoteRowComponent } from './components/customer-pages/customer-firmtime-quote-row/customer-firmtime-quote-row.component';
import { CustomerDropdownComponent } from './components/customer-dropdown/customer-dropdown.component';
import { PhoneNumberPipe } from './pipes/phone-number.pipe';
import { WellnessWorkOrderMaintenanceService } from './services/wellness-work-order-maintenance.service';
import { WorkWorkOrderMaintenanceService } from './services/work-work-order-maintenance.service';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';
import { TreeTypeMaintenanceComponent } from './components/tree-type-maintenance/tree-type-maintenance.component';
import { CrewLogComponent } from './components/crew-log/crew-log.component';
import { AdditionalInfoModalComponent } from './components/regional-manager-scheduling/additional-info-modal/additional-info-modal.component';
import { PriorityBacklogMaintenanceComponent } from './components/priority-backlog-maintenance/priority-backlog-maintenance.component';
import { AnonymousCustomerQuotePageComponent } from './components/customer-pages/anonymous-customer-quote-page/anonymous-customer-quote-page.component';
import { LoadingSwirlyComponent } from './components/loading-swirly/loading-swirly.component';
import { QuoteRequestMaintenanceComponent } from './components/quote-request-maintenance/quote-request-maintenance.component';
import { ServicesSectionComponent } from './components/quote-request-maintenance/services-section/services-section.component';
import { DescriptionSectionComponent } from './components/quote-request-maintenance/description-section/description-section.component';
import { QuoteOptionSectionComponent } from './components/quote-request-maintenance/quote-option-section/quote-option-section.component';
import { ContactInfoSectionComponent } from './components/quote-request-maintenance/contact-info-section/contact-info-section.component';
import { WorkOrderItemComponent } from './components/regional-manager-scheduling/work-order-item/work-order-item.component';
import { OfficeManagerDashboardComponent } from './components/dashboards/office-manager-dashboard/office-manager-dashboard.component';
import { CustomErrorHandlerService } from './services/custom-error-handler.service';
import { InvoiceFullPageComponent } from './components/invoice-full-page/invoice-full-page.component';
import { AddCashComponent } from './components/invoice-full-page/add-cash/add-cash.component';
import { PaymentOptionsTdComponent } from './components/invoice-full-page/payment-options-td/payment-options-td.component';
import { ChargeCardComponent } from './components/invoice-full-page/charge-card/charge-card.component';
import { RefundInvoiceComponent } from './components/invoice-full-page/refund-invoice/refund-invoice.component';
import { BacklogRowComponent } from './components/priority-backlog-maintenance/backlog-row/backlog-row.component';
import { SystemSettingsComponent } from './components/system-settings/system-settings.component';
import { SettingRowComponent } from './components/system-settings/setting-row/setting-row.component';
import { CommissionReportComponent } from './components/commission-report/commission-report.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ArReportComponent } from './components/ar-report/ar-report.component';
import { LegalAcceptanceVerbiageComponent } from './components/legal-acceptance-verbiage/legal-acceptance-verbiage.component';
import { TimePipe } from './pipes/time.pipe';
import { InvoicesComponent } from './components/invoice-full-page/invoices/invoices.component';
import { MessageToCustomerTemplateComponent } from './components/message-to-customer-template/message-to-customer-template.component';
import { ExportPageComponent } from './components/export-page/export-page.component';
import { ExportRowComponent } from './components/export-page/export-row/export-row.component';
import { BasicWorkOrderDropdownComponent } from './components/basic-work-order-dropdown/basic-work-order-dropdown.component';

export function configServiceFactory(appconfigService: AppConfigService) {
  // return () => appconfigService.init(window['tempConfigStorage']);
  return () => appconfigService.loadAppConfig();
}

@NgModule({
  declarations: [
    AppComponent,
    WellnessDayViewComponent,
    WellnessWeekViewComponent,
    SchedulingTabsComponent,
    WorkDayViewComponent,
    WorkWeekViewComponent,
    CrewsAndEquipmentPanelComponent,
    WorkOrdersPanelComponent,
    EquipmentCardComponent,
    EmployeeCardComponent,
    CrewCardComponent,
    TaskCardComponent,
    TwoDayCalendarViewComponent,
    WeekCalendarViewComponent,
    ScheduledItemBucketCardComponent,
    SkillListComponent,
    EmployeeListComponent,
    EmployeeMaintenanceComponent,
    SkillMaintenanceComponent,
    PeopleFilterPipe,
    EquipmentFilterPipe,
    WorkOrderFilterPipe,
    CrewFilterPipe,
    EquipmentListComponent,
    EquipmentMaintenanceComponent,
    CrewCreationComponent,
    PriorityMaintenanceComponent,
    DateGreaterThanValidatorDirective,
    DateLessThanValidatorDirective,
    DateGreaterThanAllSubrangeValidatorDirective,
    DateLessThanAllSubrangeValidatorDirective,
    NumericDirective,
    ChemicalMaintenanceComponent,
    ChemicalLocationMaintenanceComponent,
    ChemicalMethodMaintenanceComponent,
    ChemicalComboMaintenanceComponent,
    CrewCreationCardComponent,
    EquipmentTypeMaintenanceComponent,
    CrewCreationCardComponent,
    WellnessTaskTemplateListComponent,
    WellnessTaskTemplateMaintenanceComponent,
    WorkTaskTemplateMaintenanceComponent,
    WorkTaskTemplateListComponent,
    CustomerListComponent,
    CustomerMaintenanceComponent,
    WorkTaskMaintenanceComponent,
    WellnessTaskMaintenanceComponent,
    WorkOrderListComponent,
    SchedulingMultidayComponent,
    MultidayJobsPanelComponent,
    MultidayJobCardComponent,
    MultidaySubrangeCardComponent,
    UnavailableEquipmentAndEmployeesComponent,
    MultidayEquipmentAndEmployeesComponent,
    LoginComponent,
    MyScheduleComponent,
    WelcomePageComponent,
    CustomerCommentsComponent,
    PesticideRecordMaintenanceComponent,
    PesticideRecordListComponent,
    WorkOrderMaintenanceCommonFieldsTopComponent,
    WellnessWorkOrderMaintenanceComponent,
    WorkOrderMaintenanceCommonFieldsBottomComponent,
    QuoteWorkOrderMaintenanceComponent,
    WorkWorkOrderMaintenanceComponent,
    WellnessWorkOrderCardComponent,
    WorkWorkOrderCardComponent,
    ScheduledBucketWellnessWorkOrderCardComponent,
    ScheduledBucketWorkWorkOrderCardComponent,
    CustomerFilterPipe,
    SurveyHeaderFilterPipe,
    MyScheduleCrewCardComponent,
    RequestedQuoteListComponent,
    RequestedQuoteMaintenanceComponent,
    QuoteRequestFilterPipe,
    MultidayJobsWorkPanelComponent,
    PriorityAndDateSelectorComponent,
    WorkOrderFilterBarComponent,
    SurveyMaintenanceComponent,
    SurveyResponseComponent,
    SurveyListComponent,
    CustomerLandingPageComponent,
    CustomerCurrentTabComponent,
    CustomerHistoryTabComponent,
    CustomerWorkOrderListComponent,
    WorkOrderMaintenanceWellnessTaskCardComponent,
    WorkOrderMaintenanceWorkTaskCardComponent,
    StripeBillingComponent,
    QuotePaymentComponent,
    CustomerBillingProfileComponent,
    CustomerContactComponent,
    ZipCodeMaintenanceComponent,
    ZipCodeAssignmentComponent,
    ZipCodeAssignmentItemComponent,
    DayZipCodeAssignmentComponent,
    DayZipCodeItemComponent,
    SchedulePageComponent,
    QuoteRequestItemComponent,
    NewEventModalComponent,
    DayZipCodeViewOnlyComponent,
    BlobManagerComponent,
    EmployeeSkillUploadComponent,
    CustomerMainTabComponent,
    CustomerWorkOrderRowComponent,
    CustomerQuoteRowComponent,
    CustomerQuotePageComponent,
    BillingProfileEntryModalComponent,
    QuoteBillingComponent,
    BillingProfileEntryComponent,
    PasswordChangeComponent,
    PasswordResetComponent,
    ContactInfoComponent,
    QuoteBillingModalComponent,
    CustomerFirmtimeSchedulingComponent,
    InvoicePartialWorkComponent,
    InvoicePartialWorkRowComponent,
    RequestChangesDialogComponent,
    TaskHoursEntryComponent,
    CustomerCommentViewOnlyComponent,
    SignatureAreaComponent,
    ReportingComponent,
    CustomerFirmtimeQuoteRowComponent,
    CustomerDropdownComponent,
    PhoneNumberPipe,
    TreeTypeMaintenanceComponent,
    CrewLogComponent,
    AdditionalInfoModalComponent,
    PriorityBacklogMaintenanceComponent,
    AnonymousCustomerQuotePageComponent,
    LoadingSwirlyComponent,
    QuoteRequestMaintenanceComponent,
    ServicesSectionComponent,
    DescriptionSectionComponent,
    QuoteOptionSectionComponent,
    ContactInfoSectionComponent,
    WorkOrderItemComponent,
    OfficeManagerDashboardComponent,
    InvoiceFullPageComponent,
    AddCashComponent,
    PaymentOptionsTdComponent,
    ChargeCardComponent,
    RefundInvoiceComponent,
    BacklogRowComponent,
    SystemSettingsComponent,
    SettingRowComponent,
    CommissionReportComponent,
    ArReportComponent,
    LegalAcceptanceVerbiageComponent,
    TimePipe,
    InvoicesComponent,
    MessageToCustomerTemplateComponent,
    ExportPageComponent,
    ExportRowComponent,
    BasicWorkOrderDropdownComponent,
    TrimDirective,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    DropdownModule,
    NgSelectModule,
    FullCalendarModule,
    TabViewModule,
    MenubarModule,
    ButtonModule,
    PanelModule,
    InputTextModule,
    CalendarModule,
    CardModule,
    ProgressBarModule,
    OverlayPanelModule,
    InputSwitchModule,
    MultiSelectModule,
    DialogModule,
    CheckboxModule,
    KeyFilterModule,
    SelectButtonModule,
    RadioButtonModule,
    InputTextareaModule,
    RadioButtonModule,
    FieldsetModule,
    AccordionModule,
    SidebarModule,
    PanelMenuModule,
    StepsModule,
    SliderModule,
    TooltipModule,
    ToastModule,
    ReactiveFormsModule,
    TableModule,
    DragDropModule,
    AppRoutingModule,
    NgbModule,
    ColorPickerModule,
    RouterModule.forRoot([]),
    NgxMaskModule.forRoot(),
    HttpClientModule,
    TextMaskModule,
    ScrollPanelModule,
    ConfirmDialogModule,
    MessageModule,
    InputMaskModule,
    RadioButtonModule,
    SignaturePadModule,
    NgxStripeModule.forRoot(),
    RadioButtonModule,
    ProgressSpinnerModule,
    NgxStripeModule.forRoot(),
    RadioButtonModule,
    SignaturePadModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [
    AppConfigService,
    {
      provide: APP_INITIALIZER,
      useFactory: configServiceFactory,
      deps: [AppConfigService],
      multi: true
    },
    {
      provide: API_BASE_URL,
      // Use a factory that return an array of dependant functions to be executed
      useFactory: (config: AppConfigService) => {
        // Easy to add or remove dependencies
        return config.appConfig.apiUrl;
      },
      deps: [AppConfigService]
    },
    {
      provide: ErrorHandler, useClass: CustomErrorHandlerService
    },
    LocationColorService,
    ScheduledBucketGenSvc,
    DragAndDropService,
    CurrencyPipe,
    SpecificationService,
    MessageService,
    ConfirmationService,
    CustomerService,
    EquipmentGenSvc,
    CrewGenSvc,
    EmployeeGenSvc,
    WellnessWorkOrderGenSvc,
    PriorityLevelGenSvc,
    RoleGenSvc,
    ScheduleCommentGenSvc,
    SkillGenSvc,
    AddressGenSvc,
    WorkTaskGenSvc,
    StateGenSvc,
    ChemicalGenSvc,
    ChemicalApplicationMethodGenSvc,
    ChemicalApplicationLocationGenSvc,
    ChemicalComboGenSvc,
    EquipmentTypeGenSvc,
    WellnessTaskGenSvc,
    WellnessTaskTemplateGenSvc,
    WorkTaskTemplateGenSvc,
    CustomerGenSvc,
    TreeTypeGenSvc,
    BigDayBucketGenSvc,
    BigDayBucketSubrangeGenSvc,
    BigDayEmployeeGenSvc,
    BigDayEquipmentGenSvc,
    MultidayDragAndDropService,
    PaymentDueOptionGenSvc,
    AuthHelperService,
    BehaviorSubjectService,
    ScriptLoaderService,
    AuthenticationGenSvc,
    QuoteGenSvc,
    WorkWorkOrderGenSvc,
    WorkOrderWorkTaskGenSvc,
    WorkOrderWellnessTaskGenSvc,
    WorkOrderPriceAdjustmentGenSvc,
    AuthGuard,
    StripeGenSvc,
    CustomerTypeGenSvc,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    PesticideApplicationRecordGenSvc,
    PriorityService,
    SharedColorService,
    CalculatedPriorityAgingColorService,
    PriorityTypeShortcutGenSvc,
    SurveyQuestionGenSvc,
    SurveyResponseGenSvc,
    NotificationsGenSvc,
    QuoteRequestGenSvc,
    BroughtByGenSvc,
    ZipCodeGenSvc,
    RegionalManagerTimeSlotGenSvc,
    RegionalManagerScheduleEventGenSvc,
    BlobGenSvc,
    QuoteAndAuthenticationGenSvc,
    WellnessTaskHourEntryGenSvc,
    CustomerNoteGenSvc,
    CustomerCommentStageGenSvc,
    WorkTaskHourEntryGenSvc,
    DatePipe,
    WellnessWorkOrderMaintenanceService,
    WorkWorkOrderMaintenanceService,
    NeighborPermissionGenSvc,
    LogGenSvc,
    WorkOrderGenSvc,
    SystemSettingGenSvc,
    ReportGenSvc,
    MessageToCustomerTemplateGenSvc,
    ExportGenSvc
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
